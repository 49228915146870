import React from "react"
import parser from "react-html-parser"
import "../css/Succeedlms-feature-list.css"
const Succeedlmsfeaturelist = ({slmtitle1, slmimg1,slmdesc1, slmtitle2, slmimg2,slmdesc2, slmtitle3, slmimg3,slmdesc3, slmtitle4, slmimg4,slmdesc4, slmtitle5, slmimg5,slmdesc5,slmtitle6, slmimg6,slmdesc6, slmtitle7, slmimg7,slmdesc7, slmtitle8, slmimg8,slmdesc8, slmtitle9, slmimg9,slmdesc9, slmtitle10, slmimg10,slmdesc10, slmtitle11, slmimg11,slmdesc11}) => {
    return (
        <>
            <div className="container feature" id="feature">
                <div className="feature-header text-center text-center">
                    <h4 className="font-weight-bold"> Comprehensive Features List </h4>
                </div>
                <section className="sec sec1">
                <div className="feature">
                    <div className="row pt-4">
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column personalization order-xl-1 order-2">
                            {parser(slmtitle1)}
                            {parser(slmdesc1)}
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-left text-center order-xl-2 order-1">
                            <img src={slmimg1}
                                alt="Unlimited Personalization"
                            />
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div> 
                </section>
                <section className="sec sec2">
                <div className="feature">
                    <div className="row pt-4">
                    <div className="col-1"></div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-right text-center">
                            <img src={slmimg2}
                                alt="Flexible Authentication"
                            />
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column flexible">
                            {parser(slmtitle2)}
                            {parser(slmdesc2)}
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div>
                </section>
                <section className="sec sec3">
                <div className="feature">
                    <div className="row pt-4">
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column personalization order-xl-1 order-2">
                            {parser(slmtitle3)}
                            {parser(slmdesc3)}
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-left text-center order-xl-2 order-1">
                            <img src={slmimg3}
                                alt="Unlimited Personalization"
                            />
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div> 
                </section>
                <section className="sec sec4">
                  <div className="feature">
                    <div className="row pt-4">
                    <div className="col-1"></div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-right text-center">
                            <img src={slmimg4}
                                alt="Flexible Authentication"
                            />
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column flexible">
                            {parser(slmtitle4)}
                            {parser(slmdesc4)}
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div>
                </section>
                <section className="sec sec5">
                <div className="feature">
                    <div className="row pt-4">
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column personalization order-xl-1 order-2">
                            {parser(slmtitle5)}
                            {parser(slmdesc5)}
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-left text-center order-xl-2 order-1">
                            <img src={slmimg5}
                                alt="Unlimited Personalization"
                            />
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div> 
                </section>
                <section className="sec sec6">
                <div className="feature">
                    <div className="row pt-4">
                    <div className="col-1"></div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-right text-center">
                            <img src={slmimg6}
                                alt="Flexible Authentication"
                            />
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column flexible margintop">
                            {parser(slmtitle6)}
                            {parser(slmdesc6)}
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div>
                </section>
                <section className="sec sec7">
                <div className="feature">
                    <div className="row pt-4">
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column personalization order-xl-1 order-2">
                            {parser(slmtitle7)}
                            {parser(slmdesc7)}
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-left text-center order-xl-2 order-1">
                            <img src={slmimg7}
                                alt="Unlimited Personalization"
                            />
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div> 
                </section>
                <section className="sec sec8">
                <div className="feature">
                    <div className="row pt-4">
                    <div className="col-1"></div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-right text-center">
                            <img src={slmimg8}
                                alt="Flexible Authentication"
                            />
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column flexible">
                            {parser(slmtitle8)}
                            {parser(slmdesc8)}
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div>
                </section>
                <section className="sec sec9">
                <div className="feature">
                    <div className="row pt-4">
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column personalization order-xl-1 order-2">
                            {parser(slmtitle9)}
                            {parser(slmdesc9)}
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-left text-center order-xl-2 order-1">
                            <img src={slmimg9}
                                alt="Unlimited Personalization"
                            />
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div> 
                </section>
                <section className="sec sec10">
                <div className="feature">
                    <div className="row pt-4">
                    <div className="col-1"></div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-right text-center">
                            <img src={slmimg10}
                                alt="Flexible Authentication"
                            />
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column flexible margintop">
                            {parser(slmtitle10)}
                            {parser(slmdesc10)}
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div>
                </section>
                <section className="sec sec11">
                <div className="feature">
                    <div className="row pt-4">
                        <div className="col-xl-5 col-md-12 col-sm-12 d-flex justify-content-center flex-column personalization order-xl-1 order-2">
                            {parser(slmtitle11)}
                            {parser(slmdesc11)}
                        </div>
                        <div className="col-xl-5 col-md-12 col-sm-12 text-xl-left text-center order-xl-2 order-1">
                            <img src={slmimg11}
                                alt="Unlimited Personalization"
                            />
                        </div>
                    <div className="col-1"></div>
                    </div>
                </div> 
                </section>
            </div>
        </>
    )
}

export default Succeedlmsfeaturelist
