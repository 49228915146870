import React from "react"
import { Link } from "gatsby"

import "../css/infosecdemo.css"
const Succeedlmsdemo = () => {
  return (
    <div className="container-fluid succeedlms-bg-grey ">
      <div className="row bg-color">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-lg-6 col-md-12 order-xl-1 order-2">
              <img
                src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/multi-ethnic-group-three-businesspeople-meeting-modern-office-two-women-man-wearing-suit-looking-laptop-computer-1.png"
                alt="succeed-works"
              />
            </div>
            <div className="col-lg-6 col-md-12 schedule-infosec-demo order-xl-2 order-1">
              <h5 className="succeedlearn-font text-white float-xl-left float-center aligncenter">
                See how Succeed can simplify <br />
                learning for your organization
              </h5>
              <div className="infosec-demobtn">
                <Link to="/contact#schedule">
                  <button className="infosec-demo-btn pull-left float-xl-left float-center btn-succeedlms-color">
                    Schedule a Demo
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  )
}

export default Succeedlmsdemo
