import React from "react"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Logoimg from "../components/Clients"
import Cathero from "../components/Category-hero"
import Advantage from "../components/Succeed-advantage"
import Succeedlmsfeaturelist from "../components/Succeedlms-feature-list"
import Quote from "../components/Testimonials"
import Succeedlmsdemo from "../components/Succeedlmsdemo"
import Succeedlmsprice from "../components/Succeedlmsprice"
import { Link } from "gatsby"
const SucceedLearn = ({ data }) => {
  let succeedlms = data.wordpressPage
  let yoast = data.wordpressPage.yoast_head
  return (
    <>
      <Layout>
        <SEO title={{ yoast }} />
        {/* Hero Section  */}
        <section className="financial-hero succeedlms-main" id="financial-hero">
          <Cathero
            data={succeedlms.acf.title}
            img={
              succeedlms.acf.image.localFile.childImageSharp.fluid.originalImg
            }
            tabimage={
              succeedlms.acf.tablet_image.localFile.childImageSharp.fluid
                .originalImg
            }
            mobimage={
              succeedlms.acf.mobile_image.localFile.childImageSharp.fluid
                .originalImg
            }
            desc={succeedlms.acf.description}
            subtitle={succeedlms.acf.subtitle}
            wordpress_id={succeedlms.wordpress_id}
          />
        </section>
        <section className="clients" id="clients">
          <Logoimg
            clientsrow={data.allWordpressAcfClientsLogos}
            title={data.wordpressPage.acf.clients_title}
          />
          <hr className="client-below" />
        </section>
        {/* Advantage Section */}
        <Advantage
          title={succeedlms.acf.adv_title}
          img1={
            succeedlms.acf.imageone.localFile.childImageSharp.fluid.originalImg
          }
          img2={
            succeedlms.acf.imagesecond.localFile.childImageSharp.fluid
              .originalImg
          }
          img3={
            succeedlms.acf.imagethree.localFile.childImageSharp.fluid
              .originalImg
          }
          imgdesc1={succeedlms.acf.image1desc}
          imgdesc2={succeedlms.acf.image2}
          imgdesc3={succeedlms.acf.image3}
        />
        <hr className="client-below" />
        <Succeedlmsfeaturelist
          slmtitle3={succeedlms.acf.slms_feature_list_title3}
          slmdesc3={succeedlms.acf.slms_feature_list_description3}
          slmimg3={
            succeedlms.acf.slms_feature_list_image3.localFile.childImageSharp
              .fluid.originalImg
          }
          slmtitle1={succeedlms.acf.slms_feature_list_title1}
          slmdesc1={succeedlms.acf.slms_feature_list_description1}
          slmimg1={
            succeedlms.acf.slms_feature_list_image1.localFile.childImageSharp
              .fluid.originalImg
          }
          slmtitle2={succeedlms.acf.slms_feature_list_title2}
          slmdesc2={succeedlms.acf.slms_feature_list_description2}
          slmimg2={
            succeedlms.acf.slms_feature_list_image2.localFile.childImageSharp
              .fluid.originalImg
          }
          slmtitle4={succeedlms.acf.slms_feature_list_title4}
          slmdesc4={succeedlms.acf.slms_feature_list_description_4}
          slmimg4={
            succeedlms.acf.slms_feature_list_image4.localFile.childImageSharp
              .fluid.originalImg
          }
          slmtitle5={succeedlms.acf.slms_feature_list_title4_copy}
          slmdesc5={succeedlms.acf.slms_feature_list_description_5}
          slmimg5={
            succeedlms.acf.slms_feature_list_image4_copy.localFile
              .childImageSharp.fluid.originalImg
          }
          slmtitle6={succeedlms.acf.slms_feature_list_title4_copy2}
          slmdesc6={succeedlms.acf.slms_feature_list_description_6}
          slmimg6={
            succeedlms.acf.slms_feature_list_image4_copy2.localFile
              .childImageSharp.fluid.originalImg
          }
          slmtitle7={succeedlms.acf.slms_feature_list_title4_copy3}
          slmdesc7={succeedlms.acf.slms_feature_list_description_7}
          slmimg7={
            succeedlms.acf.slms_feature_list_image4_copy3.localFile
              .childImageSharp.fluid.originalImg
          }
          slmtitle8={succeedlms.acf.slms_feature_list_title4_copy4}
          slmdesc8={succeedlms.acf.slms_feature_list_description_8}
          slmimg8={
            succeedlms.acf.slms_feature_list_image4_copy4.localFile
              .childImageSharp.fluid.originalImg
          }
          slmtitle9={succeedlms.acf.slms_feature_list_title4_copy5}
          slmdesc9={succeedlms.acf.slms_feature_list_description_9}
          slmimg9={
            succeedlms.acf.slms_feature_list_image4_copy5.localFile
              .childImageSharp.fluid.originalImg
          }
          slmtitle10={succeedlms.acf.slms_feature_list_title4_copy6}
          slmdesc10={succeedlms.acf.slms_feature_list_description_10}
          slmimg10={
            succeedlms.acf.slms_feature_list_image4_copy6.localFile
              .childImageSharp.fluid.originalImg
          }
          slmtitle11={succeedlms.acf.slms_feature_list_title4_copy7}
          slmdesc11={succeedlms.acf.slms_feature_list_description_11}
          slmimg11={
            succeedlms.acf.slms_feature_list_image4_copy7.localFile
              .childImageSharp.fluid.originalImg
          }
        />
        <hr style={{ margin: 0 }} />
        {/* <Succeedlmsprice /> */}
        <section className="price price-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 order-xl-1 order-2 mt-5">
                <img
                  src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Get-all-these-functionalities@2x-1.png"
                  alt="price"
                  className="img-price"
                />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 text-center order-xl-2 order-1 price-sec-content">
                <div className="row">
                  <div className="col-12">
                    <img
                      src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-329-2.png"
                      alt="price"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <img
                      src="https://backendev.succeedlearn.com/wp-content/uploads/2021/01/Take-the-entire-set-ofcourses-at-an-incredible-price-.png"
                      alt="price"
                      className="best-price-img"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pricebtn">
                    <div className="price-demobtn discuss-btn">
                      <Link to="/contact#schedule">
                        <button className="price-demo-btn btn text-white font-weight-bold">
                          Let’s discuss!
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonials" id="testimonials">
          <Quote
            testimonialtitle={"What our clients have to say"}
            title={"Roja Puppala "}
            subtitle={"Learning & Development Specialist"}
            description={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software."
            }
          />
        </section>
        <section className="infosec-demo" id="infosec-demo">
          <Succeedlmsdemo />
        </section>
      </Layout>
    </>
  )
}

export default SucceedLearn

export const succeedlmsquery = graphql`
  {
    wordpressPage(title: { eq: "Succeed LMS" }) {
      yoast_head
      wordpress_id
      acf {
        clients_title
        title
        subtitle
        description
        image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        adv_title
        image1desc
        imageone {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }
        image2
        imagesecond {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        image3
        imagethree {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        slms_feature_list_title3
        slms_feature_list_description3
        slms_feature_list_image3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title1
        slms_feature_list_description1
        slms_feature_list_image1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title2
        slms_feature_list_description2
        slms_feature_list_image2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title4
        slms_feature_list_description_4
        slms_feature_list_image4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title4_copy
        slms_feature_list_description_5
        slms_feature_list_image4_copy {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title4_copy2
        slms_feature_list_description_6
        slms_feature_list_image4_copy2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title4_copy3
        slms_feature_list_description_7
        slms_feature_list_image4_copy3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title4_copy4
        slms_feature_list_description_8
        slms_feature_list_image4_copy4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title4_copy5
        slms_feature_list_description_9
        slms_feature_list_image4_copy5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title4_copy6
        slms_feature_list_description_10
        slms_feature_list_image4_copy6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        slms_feature_list_title4_copy7
        slms_feature_list_description_11
        slms_feature_list_image4_copy7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
      }
    }

    allWordpressAcfClientsLogos {
      edges {
        node {
          acf {
            client_logo_images {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
